.create-invoice-stepper .mat-vertical-stepper-header {
	padding: var(--space-m);
}

.create-invoice-stepper .mat-stepper-vertical-line::before {
	top: calc(-1 * var(--space-s));
	bottom: calc(-1 * var(--space-s));
}

.create-invoice-stepper .mat-vertical-content-container {
	margin-left: 26px;
}

.create-invoice-stepper .mat-vertical-content {
	padding-bottom: var(--space-m);
}

.create-invoice-stepper .mat-mdc-paginator-container {
	min-height: 48px;
	margin-bottom: calc(-1 * var(--space-m));
}
