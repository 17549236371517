.action-buttons {
	display: flex;
	justify-content: center;
	gap: var(--space-xl);
	margin-top: var(--space-l);
	margin-bottom: var(--space-s);
}

.mdc-button .mdc-button__label {
	font-family: var(--font-family);
	font-size: var(--font-size);
	letter-spacing: 0;
	font-weight: normal;
	white-space: nowrap;
	width: 100%;
	overflow: hidden;
	text-overflow: ellipsis;
}

.mat-mdc-button-base.mat-mdc-unelevated-button.button-base,
.button-base {
	width: 150px;
	height: 45px;
}

.mat-mdc-button-base.mat-mdc-unelevated-button:not(:disabled) {
	color: var(--primary-contrast-color);
	background-color: var(--secondary-color);
	font-weight: normal;
}

.mat-mdc-button-base.mat-mdc-unelevated-button:not(:disabled).mat-primary {
	background: var(--primary-gradient);
	background-color: transparent;
}

.mat-mdc-button.manhattan,
.manhattan {
	background-color: var(--manhattan-color);
}

.mat-mdc-button.bronx,
.bronx {
	background-color: var(--bronx-color);
}

.mat-mdc-button.queens,
.queens {
	background-color: var(--queens-color);
}

.mat-mdc-button.brooklyn,
.brooklyn {
	background-color: var(--brooklyn-color);
}

.mat-mdc-button.staten-island,
.staten-island {
	background-color: var(--staten-island-color);
}

.mat-mdc-button.other,
.other {
	background-color: var(--other-borough-color);
}

.mat-mdc-icon-button.mat-mdc-button-base.mat-select-search-clear {
	top: var(--space-xs);
	right: var(--space-xs);
	display: flex;
	justify-content: center;
	align-items: center;
	width: 32px;
	height: 32px;
	padding: 0;
	line-height: 30px;
}
