asecrmc-base-table .mat-sort-header-content {
	text-align: left;
}

asecrmc-base-table.top-aligned-cells-table tr.mat-mdc-row {
	height: auto;
}

asecrmc-base-table.top-aligned-cells-table .mat-column-noRecords {
	vertical-align: middle;
}

asecrmc-base-table.top-aligned-cells-table .button-cell {
	margin-left: calc(var(--space-m) * -1);
}

.mat-column-delete {
	text-align: right;
}

.table-filters {
	display: flex;
	justify-content: space-between;
	align-items: flex-end;
	padding: var(--space-xs) var(--space-l);
	margin-bottom: var(--space-m);
}

.table-filters .search {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	margin-left: var(--space-m);
}

.table-filters .mat-mdc-form-field {
	width: 300px;
}

.table-filters .year-filter,
.table-filters .date-range-filter,
.table-filters .active-filter {
	display: flex;
	flex-direction: column;
	margin-right: var(--space-m);
	max-width: 120px;
}

.table-filters .year-filter > span,
.table-filters .date-range-filter > span,
.table-filters .active-filter > span {
	font-size: 0.9rem;
	margin-bottom: -2px;
}

.table-filters .year-filter .mat-mdc-form-field,
.table-filters .date-range-filter .mat-mdc-form-field,
.table-filters .active-filter .mat-mdc-form-field {
	width: 100%;
}

.table-filters .date-range-filter {
	max-width: 220px;
}

.table-filters .filters-buttons {
	display: flex;
	align-items: baseline;
}

.table-filters .filters-buttons h4 {
	margin: 0;
	margin-right: var(--space-m);
	font-size: 1.1rem;
	font-weight: 400;
}

.table-filters .filters-buttons asecrmc-form-control-filters-select {
	margin-right: var(--space-s);
}

.table-filters .filters-buttons .button-content {
	display: flex;
	align-items: center;
}

.table-filters .filters-buttons .button-content.enabled {
	color: var(--primary-dark-color);
	font-weight: 500;
}

.table-filters .filters-buttons .size {
	margin-left: var(--space-xs);
}

.mat-mdc-table .header-text {
	line-height: normal;
}

.mat-mdc-table .mat-mdc-header-cell:first-of-type,
.mat-mdc-table .mat-mdc-cell:first-of-type,
.mat-mdc-table .mat-mdc-footer-cell:first-of-type {
	padding-left: 24px;
}

/* Styles form columns filter config button. */
.table-config-wrapper {
	position: relative;
}

.table-config-wrapper__menu-button {
	position: absolute;
	top: 0;
	right: 0;
	padding: var(--space-s);
	/* padding from Material styles */
	padding-right: 24px;
	height: 60px;
	background-color: var(--surface-background-color);
	/* We need z-index so the button overlays other content. */
	z-index: 2;
}
