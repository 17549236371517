.mat-toolbar.mat-primary {
	background: var(--primary-gradient);
}

.cdk-global-scrollblock {
	overflow-y: auto;
}

.mat-mdc-paginator .mat-mdc-paginator-container {
	justify-content: center;
}

.mat-mdc-paginator .mat-mdc-paginator-range-label {
	order: 2;
	margin: 0 var(--space-m);
}

.mat-mdc-paginator .mat-mdc-paginator-navigation-previous {
	order: 1;
}

.mat-mdc-paginator .mat-mdc-paginator-navigation-next {
	order: 3;
}

quill-editor .ql-toolbar.ql-snow {
	border-top: 0;
	border-left: 0;
	border-right: 0;
}

quill-editor .ql-container.ql-snow {
	border-left: 0;
	border-right: 0;
}

.page-header {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.mat-error {
	color: var(--error-font-color);
}
