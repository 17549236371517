.single-file-file-drop {
	display: block;
	height: 100%;
}

ngx-file-drop.single-file-file-drop .ngx-file-drop__drop-zone {
	height: 100%;
	min-height: 100px;
	display: flex;
	align-items: center;
	justify-content: center;
	border: 2px dashed var(--primary-color);
}
