/* Checkbox indent */
.mat-mdc-checkbox .mdc-checkbox {
	padding: 1px;
	margin: var(--space-s);
}

/* Label */
.mat-mdc-checkbox .mdc-form-field {
	width: 100%;
}

.mat-mdc-checkbox .mdc-label {
	width: 100%;
	min-width: 0;
}

/* Ripple */
.mat-mdc-checkbox .mat-mdc-checkbox-touch-target,
.mat-mdc-checkbox .mdc-checkbox__ripple {
	--checkbox-target-size: 35px;
	position: absolute;
	height: var(--checkbox-target-size);
	width: var(--checkbox-target-size);
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

/* Checkbox background and mark */
.mdc-checkbox
	.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
	~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background {
	--checkbox-background-size: 20px;
	width: var(--checkbox-background-size);
	height: var(--checkbox-background-size);
}

.mat-mdc-checkbox
	.mdc-checkbox
	.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate):not([data-indeterminate="true"])
	~ .mdc-checkbox__background {
	border-radius: 4px;
	border-color: rgba(0, 0, 0, 0.54);
}

.mat-mdc-checkbox
	.mdc-checkbox
	.mdc-checkbox__native-control:enabled
	~ .mdc-checkbox__background
	.mdc-checkbox__checkmark {
	border-radius: 4px;
	color: var(--surface-background-color);
}

.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__background {
	left: 0;
	top: 0;
}

.mat-mdc-checkbox
	.mdc-checkbox
	.mdc-checkbox__native-control:enabled
	~ .mdc-checkbox__background
	.mdc-checkbox__mixedmark {
	border-color: var(--surface-background-color);
}
