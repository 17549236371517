.mat-mdc-tab-nav-bar .mat-mdc-tab-links {
	padding: 0 var(--space-l);
}

.mat-mdc-tab-nav-bar .mat-mdc-tab-links .mat-mdc-tab-link {
	letter-spacing: 0;
	min-width: 100px;
	height: 58px;
	padding: 0 var(--space-m);
	opacity: 1;
}

.mat-mdc-tab-nav-bar .mat-mdc-tab-link .mdc-tab__text-label {
	font-size: 1.2rem;
	color: var(--base-color);
}

.mat-mdc-tab-nav-bar .mat-mdc-tab-link.mdc-tab--active .mdc-tab__text-label,
.mat-mdc-tab-nav-bar .mat-mdc-tab-link.mdc-tab--active:hover .mdc-tab__text-label,
.mat-mdc-tab-nav-bar .mat-mdc-tab-link.mdc-tab--active:focus .mdc-tab__text-label {
	color: var(--primary-dark-color);
}

.mat-mdc-tab-nav-bar .mat-mdc-tab-link .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar .mat-mdc-tab-link.mdc-tab--active:hover .mdc-tab-indicator__content--underline,
.mat-mdc-tab-nav-bar .mat-mdc-tab-link.mdc-tab--active:focus .mdc-tab-indicator__content--underline {
	border-color: var(--primary-dark-color);
}
