@use "@angular/material" as mat;

// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
// Plus imports for other components in your app.

@include mat.all-component-typographies();
@include mat.core();

$asecrm-primary: (
	main: var(--primary-color),
	dark: var(--primary-dark-color),
	light: var(--primary-light-color),
	contrast: (
		main: var(--primary-contrast-color),
		dark: var(--primary-dark-contrast-color),
		light: var(--primary-light-contrast-color),
	),
);

$asecrm-accent: (
	main: var(--accent-color),
	dark: var(--accent-dark-color),
	light: var(--accent-light-color),
	contrast: (
		main: var(--accent-contrast-color),
		dark: var(--accent-dark-contrast-color),
		light: var(--accent-light-contrast-color),
	),
);

$asecrm-warn: (
	main: var(--warn-color),
	dark: var(--warn-dark-color),
	light: var(--warn-light-color),
	contrast: (
		main: var(--warn-contrast-color),
		dark: var(--warn-dark-contrast-color),
		light: var(--warn-light-contrast-color),
	),
);

/*
  Signature of mat-palette.

  mat.define-palette(
    scss variable with shape { key1: color, key2: color, key3: color, contrast: { ...repeat all keys } }, (example above)
    key of primary color,
    key of darken color,
    key of lighten color,
    (optional) key of primary contrast - default contrast[key_of_primary_color]
  )
*/

$app-primary: mat.m2-define-palette($asecrm-primary, main, dark, light);
$app-accent: mat.m2-define-palette($asecrm-accent, main, dark, light);

// The warn palette is optional (defaults to red).
$app-warn: mat.m2-define-palette($asecrm-warn, main, dark, light);

// App typography configuration.
$app-typography: mat.m2-define-typography-config(
	$font-family: var(--font-family),
);

// Create the theme object.
$app-theme: mat.m2-define-light-theme(
	(
		color: (
			primary: $app-primary,
			accent: $app-accent,
			warn: $app-warn,
		),
		typography: $app-typography,
	)
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($app-theme);
