/* stylelint-disable declaration-no-important */
/* Form field */
.mat-mdc-form-field {
	padding: 1.3em 0;
	margin: 0.25em 0;
}

.mat-mdc-form-field,
.mat-mdc-floating-label {
	--mat-form-field-container-text-font: inherit;
	--mat-form-field-container-text-size: var(--font-size);
	--mat-form-field-container-text-line-height: 1.13;
	--mat-form-field-container-text-tracking: 0;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-text-field__input {
	--mdc-outlined-text-field-input-text-color: rgba(0, 0, 0, 0.87);
}

.mat-mdc-form-field .mat-mdc-form-field-flex {
	padding-right: 0.75em;
}

.mat-mdc-form-field.large .mat-mdc-form-field-flex {
	padding: 0.5em 0.75em;
}

.mat-mdc-form-field-type-mat-select.large .mat-mdc-form-field-flex {
	padding: 0.5em 0;
	padding-right: 0.75em;
}

.mat-mdc-form-field.no-padding {
	padding: 0;
}

.mat-mdc-form-field .mdc-text-field {
	padding-right: 0;
}

.mat-mdc-form-field .mat-mdc-text-field-wrapper {
	background-color: var(--surface-background-color);
}

.mdc-list-item.mat-mdc-option .mdc-list-item__primary-text {
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow: hidden;
}

/* Label */
.mat-mdc-form-field
	.mat-mdc-text-field-wrapper
	.mat-mdc-form-field-flex
	.mat-mdc-floating-label.mdc-floating-label--float-above {
	top: 15px;
	left: -11px;
	font-size: 1.3em;
	font-weight: bold;
	color: var(--primary-dark-color);
}

.mat-mdc-form-field
	.mat-mdc-text-field-wrapper.mdc-text-field--focused
	.mat-mdc-form-field-flex
	.mat-mdc-floating-label {
	top: 14px;
}

.mat-mdc-form-field
	.mdc-text-field--invalid:not(.mdc-text-field--disabled)
	.mat-mdc-form-field-flex
	.mat-mdc-floating-label {
	color: var(--mdc-theme-error, var(--warn-color));
}

.mat-mdc-form-field-required-marker {
	margin-left: 2px;
}

.mat-mdc-form-field.mat-form-field-appearance-outline .mdc-notched-outline .mdc-notched-outline__notch {
	width: 100% !important;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing {
	--mdc-outlined-text-field-outline-color: rgba(0, 0, 0, 0.12);
}

/* Label border top. It's not there by default. */
.mat-mdc-form-field-flex .mdc-notched-outline--notched .mdc-notched-outline__notch {
	border-top: 1px solid rgba(0, 0, 0, 0.12);
	clip-path: none;
	--webkit-clip-path: none;
}

.mdc-text-field--focused .mdc-notched-outline .mdc-notched-outline__notch {
	border-top-width: 2px;
}

.mdc-text-field--focused .mat-mdc-form-field-flex .mdc-notched-outline--notched .mdc-notched-outline__notch {
	border-top-width: 2px;
	border-left-width: 2px;
}

.mdc-text-field--disabled .mat-mdc-form-field-flex .mdc-notched-outline__notch {
	border-top-color: rgb(0 0 0 / 0.06);
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__notch,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__trailing,
.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__leading {
	border-color: var(--mdc-theme-error, var(--warn-color));
}

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused
	.mat-mdc-form-field-flex
	.mdc-notched-outline--notched
	.mdc-notched-outline__notch {
	border-top: 2px solid var(--mdc-theme-primary, var(--mdc-theme-error, var(--warn-color)));
}

/* Input */
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
	min-height: auto;

	border-top: none;
	min-width: 100px;
	width: 100%;
	padding: 0.7em 0;
}

/* Error message and hint */
.mat-mdc-form-field .mat-mdc-form-field-error-wrapper,
.mat-mdc-form-field .mat-mdc-form-field-hint-wrapper {
	padding: 0;
	left: 13px;
	position: unset;
}

.mat-mdc-form-field-subscript-wrapper.mat-mdc-form-field-bottom-align::before,
.mat-mdc-form-field-error.mat-mdc-form-field-bottom-align::before,
.mat-mdc-form-field-hint.mat-mdc-form-field-bottom-align::before {
	display: none;
}

.mat-mdc-form-field-subscript-wrapper {
	padding: 0 0.2em;
	margin-top: 0;
}

.mat-mdc-form-field-hint-wrapper,
.mat-mdc-form-field-error-wrapper {
	padding: var(--space-xs);
}

.mat-mdc-form-field-hint,
.mat-mdc-form-field-error {
	font-size: 0.95em;
	letter-spacing: 0;
}

.mat-mdc-form-field-hint {
	color: rgba(0, 0, 0, 0.6);
}

/* Icons */
.mat-mdc-form-field-icon-prefix,
.mat-mdc-form-field-icon-suffix {
	--mat-form-field-leading-icon-color: var(--faint-color);
}

.file-input .mat-mdc-button,
.mat-mdc-form-field .mat-mdc-icon-button,
.mat-mdc-form-field .mat-mdc-form-field-icon-prefix > .mat-icon,
.mat-mdc-form-field .mat-datepicker-toggle .mat-mdc-button-base {
	--button-icon-size: 24px;
	width: var(--button-icon-size);
	height: var(--button-icon-size);
	line-height: var(--button-icon-size);
	padding: 0;
}

.mat-mdc-form-field .mat-datepicker-toggle .mat-mdc-button-base .mat-mdc-button-touch-target {
	width: calc(1.5 * var(--button-icon-size));
	height: calc(1.5 * var(--button-icon-size));
}

.file-input .mat-mdc-icon-button .mat-icon {
	--icon-size: 20px;
	color: var(--warn-color);
	width: var(---icon-size);
	height: var(--icon-size);
	font-size: var(--icon-size);
	line-height: var(--button-icon-size);
}

/* Select input */
.mat-mdc-form-field .mat-mdc-select {
	font-size: inherit;
	font-family: inherit;
	letter-spacing: inherit;
	line-height: inherit;
}

.mat-mdc-form-field .mat-mdc-select-arrow-wrapper {
	--select-icon-size: 16px;
	height: var(--select-icon-size);
	width: var(--select-icon-size);
	transform: none;
	justify-content: center;
}

.mat-mdc-select-panel .mat-mdc-option {
	font-size: var(--font-size);
}

/* Since the `hidden` attribute is no longer supported in material inputs */
.input-hidden {
	display: none !important;
}

.large-text-editor .ql-container {
	height: 400px;
}

.mat-mdc-form-field.search-control {
	width: 100%;
	font-size: var(--font-size);
	padding: var(--space-s);
	padding-bottom: var(--space-xs);
}

.file-input .mdc-text-field--disabled {
	pointer-events: inherit;
}
