/* Custom application color and font settings */
:root {
	/* Font */
	--font-size: 14px;
	--font-family: "Roboto", sans-serif;
	--h1-font-size: 2.3rem;
	--h2-font-size: 1.5rem;

	/* Styles that don't represent the brand (stole this phrase from material.io) */

	/* Base color for a body text. */
	--base-color: #3a3b3f;

	/* Background color appears behind scrollable content. */
	--base-background-color: #f9fafc;

	/* Color for a text that's not important or it should not draw attention. */
	--faint-color: hsl(0, 0%, 70%);
	--faint-dark-color: hsl(0, 0%, 40%);
	--faint-light-color: hsl(0, 0%, 90%);

	/* Font color for surface background. */
	--surface-font-color: var(--primary-dark-color);
	/* Affect surfaces of components, such as cards, sheets, and menus */
	--surface-background-color: #ffffff;

	/* Font color for error messages. */
	--error-font-color: #ee5757;
	/* In case error is a block with a message inside. */
	--error-background-color: #ffffff;

	/* Elements spacing. */
	--space-m: 15px;
	--space-xs: calc(var(--space-m) * 0.25);
	--space-s: calc(var(--space-m) * 0.5);
	--space-l: calc(var(--space-m) * 1.5);
	--space-xl: calc(var(--space-m) * 2.5);

	/* Brand colors */
	--primary-color: #3368b6;
	--primary-dark-color: #1c3645;
	--primary-light-color: #c5cae9;
	--primary-contrast-color: white;
	--primary-dark-contrast-color: white;
	--primary-light-contrast-color: black;

	--accent-color: #6e7684;
	--accent-dark-color: #cc7e18;
	--accent-light-color: #e4c8a3;
	--accent-contrast-color: white;
	--accent-dark-contrast-color: white;
	--accent-light-contrast-color: black;

	--warn-color: #da3e33;
	--warn-dark-color: #aa190f;
	--warn-light-color: #e4746c;
	--warn-contrast-color: white;
	--warn-dark-contrast-color: white;
	--warn-light-contrast-color: black;

	/* Util properties. Util properties must not define new values. */
	--radius: calc(var(--font-size) * 0.75);
	--border-color: var(--faint-light-color);

	--primary-gradient: linear-gradient(180deg, #3a79c6 0%, #254897 100%);
	--primary-disabled-gradient: linear-gradient(180deg, #7299c9 0%, #4e628f 100%);
	--secondary-color: #6e7684;
	--dark-menu-panel-color: #4e5563;

	--manhattan-color: #fa9171;
	--bronx-color: #32c5ff;
	--queens-color: #f7b502;
	--brooklyn-color: #46be90;
	--staten-island-color: #6236ff;
	--other-borough-color: #c5c3cd;

	--calendar-blocked-day: #f2f2f2;
	--scheduled-device-date-highlight: #008000;
	--error-table-row-background: #ffe5e3;
}
