.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
	.mat-calendar-body-active
	> .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
	background-color: #2e384d33;
}

.mat-calendar-body-in-range::before {
	background-color: var(--primary-light-color);
}

.mat-mdc-form-field.mat-form-field-appearance-outline .mat-mdc-input-element[type="time"]::after {
	content: none;
}
