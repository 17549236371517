.dialog-form {
	width: 450px;
}

.dialog-form-field {
	display: flex;
	align-items: center;
	flex-direction: row;
	justify-content: space-between;
}

.dialog-form-field-label {
	flex: 1;
	margin-right: var(--space-m);
	font-size: 1.1rem;
}

.mat-mdc-dialog-title.mdc-dialog__title {
	padding: 0;
}

.mat-mdc-dialog-title.mdc-dialog__title::before {
	display: none;
}
