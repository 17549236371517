// Custom application styles.
@import "./variables";
@import "./angular-material-custom-theme";
@import "./elements/style";

*,
*::after,
*::before {
	box-sizing: border-box;
}

html,
body {
	height: 100%;
}

html {
	font-size: var(--font-size);
	font-family: var(--font-family);
	color: var(--base-color);
}

body {
	margin: 0;
	padding: 0;
	background-color: var(--base-background-color);
}

h1 {
	font-size: var(--h1-font-size);
}

h2 {
	font-size: var(--h2-font-size);
}

h3 {
	margin: var(--space-m) 0;
}

h1,
h2,
h3,
h4,
h5 {
	color: var(--surface-font-color);
}

hr {
	margin: var(--space-m) 0;
	border: 0;
	border-bottom: 1px solid var(--border-color);
}

b {
	color: var(--primary-dark-color);
}

a {
	color: var(--primary-color);
}

input[type="search"]::-webkit-search-cancel-button {
	/* Remove default */
	-webkit-appearance: none;
}

input.mat-mdc-input-element::-webkit-calendar-picker-indicator {
	display: block;
}

.mat-divider.mat-divider-horizontal {
	margin: var(--space-m) 0;
}

@media print {
	body {
		print-color-adjust: exact;
	}

	@page {
		size: landscape;
		margin: 5mm;
	}
}
