/* stylelint-disable declaration-no-important */
.mat-mdc-menu-panel .mat-mdc-menu-content .mat-mdc-menu-item .mdc-list-item__primary-text {
	font-size: var(--font-size);
	width: 100%;
}

.mat-mdc-menu-panel.filters-menu {
	/* The material utilizes a system of specificity, which is expressed in the repetition of the same selector */
	max-width: min-content !important;
	width: 100%;
	min-height: 32px;
	overflow: hidden !important;
}

.mat-mdc-menu-panel.send-proposal-menu .mat-mdc-menu-content {
	padding: 0;
}

.mat-mdc-menu-panel.send-proposal-menu .mat-mdc-menu-content .mat-mdc-menu-item {
	height: 3rem;
	line-height: 3rem;
}

.mat-mdc-menu-panel.filters-menu .mat-mdc-menu-content {
	padding: 0;
}

.mat-mdc-menu-panel.filters-menu .mat-mdc-menu-content .mat-mdc-menu-item {
	min-height: 2.3rem;
	line-height: 2.3rem;
	margin: var(--space-xs) 0;
	padding: 0 calc(1.5 * var(--space-s));
}

.mat-mdc-menu-panel.filters-menu .mat-mdc-menu-content .mat-mdc-menu-item .mat-icon {
	margin: 0;
}

.mat-mdc-menu-panel.filters-menu .menu-button-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.menu-button-content {
	display: flex;
	align-items: center;
	gap: 10px;
}

.menu-button-content .label {
	overflow: hidden;
	text-overflow: ellipsis;
}

.menu-button-content .filter-select-icon {
	--icon-size: 20px;
	margin: 0;
	width: var(--icon-size);
	height: var(--icon-size);
	font-size: var(--icon-size);
}

.small {
	width: 120px;
}

.medium {
	width: 200px;
}

.large {
	width: 300px;
}
